/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Box, SEO } from '../components'

export const NotFoundPage = () => (
  <Box>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Box>
)

export default NotFoundPage
